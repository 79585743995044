import { LOCALES } from '@constants/constants';
import dayjs from 'dayjs';
import { getLocaleFromBrowserLanguage } from 'grab-locale';
const defaultTimeSlot = '11:00 AM - 11:00 PM';
export function convertTimeSlotObjectToString(time_slot) {
    if (!time_slot)
        return '';
    const convertTime = (time) => {
        const [hour24, minute] = time.split(':');
        const hour12 = Number(hour24) % 12 || 12;
        const ampm = Number(hour24) < 12 ? 'AM' : 'PM';
        return `${hour12}:${minute} ${ampm}`;
    };
    if (!time_slot)
        return '';
    const start12HourFormat = convertTime(time_slot.from);
    const end12HourFormat = convertTime(time_slot.to);
    return `${start12HourFormat} - ${end12HourFormat}`;
}
export function convertTimeSlotStringToObject(timeSlot) {
    // Split the string by ' - '
    const [fromTime, toTime] = timeSlot.split(' - ');
    // Construct and return the object
    return {
        from: convertTo24Hour(fromTime),
        to: convertTo24Hour(toTime),
    };
}
function convertTo24Hour(time) {
    const [timeValue, period] = time.split(' ');
    let [hours, minutes] = timeValue.split(':');
    // Convert to 24 hour format
    if (period.toLowerCase() === 'pm' && hours !== '12') {
        hours = (parseInt(hours) + 12).toString();
    }
    // format hours as "00"
    hours = ('0' + hours).slice(-2);
    return `${hours}:${minutes}`;
}
export function convertTimeSlotObjectToStringSubtractOneMinute(time_slot) {
    if (!time_slot)
        return '';
    const convertTime = (time, subtractMinute = false) => {
        let [hour24, minute] = time.split(':');
        if (subtractMinute) {
            const totalMinutes = parseInt(hour24) * 60 + parseInt(minute) - 1;
            hour24 = Math.floor(totalMinutes / 60).toString();
            minute = (totalMinutes % 60).toString();
        }
        const hour12 = parseInt(hour24) % 12 || 12;
        const ampm = parseInt(hour24) < 12 ? 'AM' : 'PM';
        // Ensuring the minutes string always has 2 digits.
        minute = ('0' + minute).slice(-2);
        return `${hour12}:${minute} ${ampm}`;
    };
    const start12HourFormat = convertTime(time_slot.from);
    const end12HourFormat = convertTime(time_slot.to, true);
    return `${start12HourFormat} - ${end12HourFormat}`;
}
export function convertTimeSlotStringToObjectAddOneMinute(timeSlot) {
    // Split the string by ' - '
    const [fromTime, toTime] = timeSlot.split(' - ');
    // Construct and return the object
    return {
        from: convertTo24Hour(fromTime),
        to: convertTo24HourAddOneMinute(toTime),
    };
}
function convertTo24HourAddOneMinute(time) {
    let [timeValue, period] = time.split(' ');
    let [hours, minutes] = timeValue.split(':');
    // Add a minute
    let totalMinutes = parseInt(hours) * 60 + parseInt(minutes) + 1;
    hours = Math.floor(totalMinutes / 60).toString();
    minutes = (totalMinutes % 60).toString();
    // Convert hours to 24 hour format
    hours = (parseInt(hours) + (period.toLowerCase() === 'pm' && hours !== '12' ? 12 : 0)).toString();
    // format hours as "00"
    hours = ('0' + hours).slice(-2);
    minutes = ('0' + minutes).slice(-2);
    return `${hours}:${minutes}`;
}
export function reverseTimeSlot(timeSlot) {
    if (!timeSlot)
        return '';
    return timeSlot
        .replace(' AM', '#temp#')
        .replace(' PM', ' AM')
        .replace('#temp#', ' PM');
}
export function adjustTimeSlot(timeSlot) {
    if (!timeSlot)
        return '';
    const toMinutes = (time) => {
        const matches = time.match(/(\d+):(\d+)\s(AM|PM)/);
        if (!matches) {
            throw new Error('Invalid time format');
        }
        const [_, hourStr, minuteStr, period] = matches;
        let hours = parseInt(hourStr, 10);
        let minutes = parseInt(minuteStr, 10);
        if (period === 'PM' && hours < 12)
            hours += 12;
        if (period === 'AM' && hours === 12)
            hours = 0;
        return hours * 60 + minutes;
    };
    const to12HourFormat = (minutes) => {
        const period = minutes >= 720 ? 'PM' : 'AM';
        let hours = Math.floor(minutes / 60) % 24; // Corrected to % 24 for proper hour calculation
        minutes %= 60;
        if (hours > 12)
            hours -= 12;
        if (hours === 0)
            hours = 12; // Adjust the hour if midnight or noon
        return `${hours}:${String(minutes).padStart(2, '0')} ${period}`;
    };
    const [startTime, endTime] = timeSlot.split(' - ').map(toMinutes);
    const adjustedStartTime = to12HourFormat((endTime + 1) % 1440); // Add one minute to the end time
    const adjustedEndTime = to12HourFormat((startTime + 1439) % 1440); // Subtract one minute from the start time
    return `${adjustedStartTime} - ${adjustedEndTime}`;
}
export function getNumericYear(dateString, locale) {
    const date = new Date(dateString);
    return date.toLocaleDateString(locale, { year: 'numeric' });
}
export const convertTimeSlots = (timeSlot) => {
    const [startTimeStr, endTimeStr] = timeSlot.split(' - ');
    let [startHour] = startTimeStr.split(':');
    let [endHour] = endTimeStr.split(':');
    // Adjust hours based on AM or PM.
    if (startTimeStr.includes('PM') && Number(startHour) !== 12)
        startHour = String(Number(startHour) + 12);
    if (endTimeStr.includes('AM') && Number(endHour) === 12)
        endHour = '00';
    if (endTimeStr.includes('PM') && Number(endHour) !== 12)
        endHour = String(Number(endHour) + 12);
    // Return start and end times as number for easy comparison
    return [Number(startHour), Number(endHour)];
};
export const isComplimentary = (slotA = defaultTimeSlot, slotB = defaultTimeSlot) => {
    if (!slotA || !slotB)
        return false;
    const [startA, endA] = convertTimeSlots(slotA);
    const [startB, endB] = convertTimeSlots(slotB);
    // If start time of slotA is same as end time of slotB and
    // end time of slotA is same as start time of slotB, then they are complementary.
    return startA === endB && endA === startB;
};
const format = (d) => `${d.getDate()} ${d.toLocaleString('default', {
    month: 'short',
})} ${d.getFullYear()}`;
const isWeekday = (d) => d.getDay() % 6 !== 0;
export function addBusinessDays(numDays) {
    if (numDays === undefined)
        return '';
    let currentDate = new Date();
    while (numDays > 0) {
        currentDate.setDate(currentDate.getDate() + 1);
        if (isWeekday(currentDate))
            numDays--;
    }
    return format(currentDate);
}
export const getDateFormat = (date, locale, withDate = true) => {
    const monthFormat = dayjs(date).month() + 1 < 10 ? 'M' : 'MM';
    const dayFormat = dayjs(date).date() < 10 ? 'D' : 'DD';
    switch (locale) {
        case 'zh':
            return withDate
                ? `YYYY 年 ${monthFormat} 月 ${dayFormat} 日`
                : `YYYY 年 ${monthFormat} 月`;
        default:
            return withDate ? 'D MMM YYYY' : 'MMM YYYY';
    }
};
export const getCurrentLocale = () => {
    return getLocaleFromBrowserLanguage({
        defaultLocale: 'en',
        availableLocales: LOCALES,
        navigator: window.navigator,
    });
};
