var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from 'react';
import { render } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import I18n from 'i18n-js';
import { datadogLogs } from '@datadog/browser-logs';
import { getLocaleFromBrowserLanguage } from 'grab-locale';
import Storage from 'grab-storage';
import 'grab-ui/dist/theme.less';
import * as Sentry from '@sentry/browser';
import App from './components/app';
import './index.less';
import { JSBridge } from '@helpers/jsBridge';
import { initializeAppsAttribute, createIsFirstVisitState, getEnvironment, } from '@helpers';
import { setGstRateByDate } from '@helpers/money';
import { ConfigProvider } from 'antd-mobile';
import enUS from 'antd-mobile/es/locales/en-US';
import dayjs from 'dayjs';
import { LOCALES } from '@constants/constants';
window.Grab = {
    config: {},
    storage: Storage,
};
window.JSBridge = new JSBridge();
setGstRateByDate();
initializeAppsAttribute();
createIsFirstVisitState();
const locale = getLocaleFromBrowserLanguage({
    defaultLocale: 'en',
    availableLocales: LOCALES,
    navigator: window.navigator,
});
const renderApp = () => {
    render(React.createElement(BrowserRouter, null,
        React.createElement(ConfigProvider, { locale: enUS },
            React.createElement(App, null))), document.getElementById('root'));
};
window
    .fetch(require('config.json'))
    .then((configResponse) => __awaiter(void 0, void 0, void 0, function* () {
    if (configResponse.status !== 200)
        return;
    window.Grab.config = yield configResponse.json();
    window
        .fetch(require(`public/locales/${locale}.json`))
        .then((localeResponse) => __awaiter(void 0, void 0, void 0, function* () {
        if (localeResponse.status !== 200)
            return;
        I18n.translations[locale] = yield localeResponse.json();
        I18n.locale = locale;
        dayjs.locale(locale);
        const isProductionEnv = process.env.NODE_ENV === 'production';
        const datadogClientTokenExists = window.Grab.config.datadog && window.Grab.config.datadog.clientToken;
        if (isProductionEnv && datadogClientTokenExists) {
            datadogLogs.init({
                clientToken: window.Grab.config.datadog.clientToken,
                site: 'datadoghq.com',
                service: window.Grab.config.appId,
                env: window.Grab.config.env,
                version: process.env.APP_VERSION,
                forwardErrorsToLogs: true,
                sessionSampleRate: 100,
            });
            window.onerror = (message, source, lineno, colno, error) => {
                if (error) {
                    datadogLogs.logger.error(error.message || '', {
                        error: { stack: error.stack },
                    });
                }
            };
        }
        const sentryDsn = window.Grab.config.sentryDsn;
        if (sentryDsn) {
            Sentry.init({
                dsn: sentryDsn,
                debug: getEnvironment() === 'dev',
                release: `jaya@master`,
                environment: getEnvironment(),
            });
        }
        renderApp();
    }))
        .catch(err => {
        Sentry.captureException(err);
        datadogLogs.logger.error(err.message, { error: { stack: err.stack } });
        console.error(err);
    });
}))
    .catch(err => {
    Sentry.captureException(err);
    datadogLogs.logger.error(err.message, { error: { stack: err.stack } });
    console.error(err);
});
