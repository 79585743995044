import React from 'react';
const NotFoundPage = React.lazy(() => import('src/routes/not-found'));
const InterestedPage = React.lazy(() => import('src/pages/interested-page'));
const SharingPreferencePage = React.lazy(() => import('src/pages/sharing-preference-page'));
const SharingPreferencePageV2 = React.lazy(() => import('src/pages/sharing-preference-page-v2'));
const SharingCarModelPreferencePage = React.lazy(() => import('src/pages/sharing-car-model-preference-page'));
const EditSharingPreferencePage = React.lazy(() => import('src/pages/edit-sharing-preference-page'));
const SearchDriversResultPage = React.lazy(() => import('src/pages/search-drivers-result-page'));
const SearchDriversResultPageV2 = React.lazy(() => import('src/pages/search-drivers-result-page-v2'));
const UploadDocumentPage = React.lazy(() => import('src/pages/upload-documents-page'));
const DocumentCheckPage = React.lazy(() => import('src/pages/document-check-page'));
const RentalPreferencePage = React.lazy(() => import('src/pages/rental-preference-page'));
const LandingPage = React.lazy(() => import('src/pages/landing-page'));
const ProposalInvitationPage = React.lazy(() => import('src/pages/proposal-invitation-page'));
const ProposalInvitationPageV2 = React.lazy(() => import('src/pages/proposal-invitation-page-v2'));
const ProposalProgressPage = React.lazy(() => import('src/pages/proposal-progress-page'));
const ProposalProgressPageV2 = React.lazy(() => import('src/pages/proposal-progress-page-v2'));
// Rental Summary Pages
const RentalSummaryPage = React.lazy(() => import('src/pages/rental-summary-page'));
const RentalSummaryPageV2 = React.lazy(() => import('src/pages/rental-summary-page-v2'));
const RentalAgreementPage = React.lazy(() => import('src/pages/rental-summary-page/mini-pages/RentalAgreement'));
const SupplementalAgreementPage = React.lazy(() => import('src/pages/rental-summary-page/mini-pages/SupplementalAgreement'));
const RentalDepositPage = React.lazy(() => import('src/pages/rental-deposit-page'));
const ScheduleVehicleReturnPage = React.lazy(() => import('src/pages/schedule-vehicle-return-page'));
const ChevronDiscountFormPage = React.lazy(() => import('src/pages/chevron-discount-form'));
const RebateIntroductionPage = React.lazy(() => import('src/pages/rebate-introduction-page'));
const RebateIntroductionPageTH = React.lazy(() => import('src/pages/rebate-introduction-page-th'));
const LandingPageV2 = React.lazy(() => import('src/pages/car-sharing-v2/landing-page/LandingPageV2'));
const VehicleProviderListingForm = React.lazy(() => import('src/pages/car-sharing-v2/vehicle-provider-listing-form-page'));
const VehicleProviderListingPage = React.lazy(() => import('src/pages/car-sharing-v2/vehicle-provider-listing-page'));
const VehicleProviderAddDriverFormPage = React.lazy(() => import('src/pages/car-sharing-v2/vehicle-provider-add-driver-form-page'));
const DriverSharingPage = React.lazy(() => import('src/pages/car-sharing-v2/driver-sharing-page'));
const DriverListingDetailPage = React.lazy(() => import('src/pages/car-sharing-v2/driver-listing-detail-page'));
const DriverProposalDetailPage = React.lazy(() => import('src/pages/car-sharing-v2/driver-proposal-detail-page'));
const RentalAgreementSample = React.lazy(() => import('src/pages/car-sharing-v2/mini-pages/RentalAgreementSample'));
// car sharing v2a
const LandingPageV2A = React.lazy(() => import('src/pages/car-sharing-v2a/landing-page'));
const DriverListingsPage = React.lazy(() => import('src/pages/car-sharing-v2a/driver-sharing-page'));
const CreateEditListingPage = React.lazy(() => import('src/pages/car-sharing-v2a/create-edit-listing-page'));
const ListingPage = React.lazy(() => import('src/pages/car-sharing-v2a/listing-page'));
const ListingReliefPage = React.lazy(() => import('src/pages/car-sharing-v2a/listing-relief-page'));
const DriverProposalDetailPageV2A = React.lazy(() => import('src/pages/car-sharing-v2a/driver-proposal-detail-page'));
const VehicleProviderAddDriverFormPageV2A = React.lazy(() => import('src/pages/car-sharing-v2a/vehicle-provider-add-driver-form-page'));
const RedirectPage = React.lazy(() => import('src/pages/car-sharing-v2a/redirect-page'));
export const ROUTES = [
    // Car Sharing Routes
    {
        pathname: '/interested',
        element: React.createElement(InterestedPage, null),
    },
    {
        pathname: '/sharing-preference',
        element: React.createElement(SharingPreferencePage, null),
    },
    {
        pathname: '/sharing-preference-v2',
        element: React.createElement(SharingPreferencePageV2, null),
    },
    {
        pathname: '/sharing-car-model-preference',
        element: React.createElement(SharingCarModelPreferencePage, null),
    },
    {
        pathname: '/edit-sharing-preference',
        element: React.createElement(EditSharingPreferencePage, null),
    },
    {
        pathname: '/search-drivers-result',
        element: React.createElement(SearchDriversResultPage, null),
    },
    {
        pathname: '/search-drivers-result-v2',
        element: React.createElement(SearchDriversResultPageV2, null),
    },
    {
        pathname: '/upload-document',
        element: React.createElement(UploadDocumentPage, null),
    },
    {
        pathname: '/document-check',
        element: React.createElement(DocumentCheckPage, null),
    },
    {
        pathname: '/rental-preference',
        element: React.createElement(RentalPreferencePage, null),
    },
    {
        pathname: '/landing-page',
        element: React.createElement(LandingPage, null),
    },
    {
        pathname: '/proposal-invitation',
        element: React.createElement(ProposalInvitationPage, null),
    },
    {
        pathname: '/proposal-invitation-v2',
        element: React.createElement(ProposalInvitationPageV2, null),
    },
    {
        pathname: '/proposal-progress',
        element: React.createElement(ProposalProgressPage, null),
    },
    {
        pathname: '/proposal-progress-v2',
        element: React.createElement(ProposalProgressPageV2, null),
    },
    // Car Sharing Routes V2
    {
        pathname: '/landing-page-v2',
        element: React.createElement(LandingPageV2, null),
    },
    {
        pathname: '/vehicle-provider-listing-form',
        element: React.createElement(VehicleProviderListingForm, null),
    },
    {
        pathname: '/vehicle-provider-listing',
        element: React.createElement(VehicleProviderListingPage, null),
    },
    {
        pathname: '/vehicle-provider-add-driver-form',
        element: React.createElement(VehicleProviderAddDriverFormPage, null),
    },
    {
        pathname: '/driver-sharing',
        element: React.createElement(DriverSharingPage, null),
    },
    {
        pathname: '/driver-listing-detail',
        element: React.createElement(DriverListingDetailPage, null),
    },
    {
        pathname: '/driver-proposal-detail',
        element: React.createElement(DriverProposalDetailPage, null),
    },
    {
        pathname: '/rental-agreement-sample',
        element: React.createElement(RentalAgreementSample, null),
    },
    // Car sharing v2a routes
    {
        pathname: '/landing-page-v2a',
        element: React.createElement(LandingPageV2A, null),
    },
    {
        pathname: '/driver-listings',
        element: React.createElement(DriverListingsPage, null),
    },
    {
        pathname: '/listing/:id',
        element: React.createElement(ListingPage, null),
    },
    {
        pathname: '/listing/:id/relief',
        element: React.createElement(ListingReliefPage, null),
    },
    {
        pathname: '/listing/create',
        element: React.createElement(CreateEditListingPage, null),
    },
    {
        pathname: '/listing/edit/:id',
        element: React.createElement(CreateEditListingPage, null),
    },
    {
        pathname: '/proposal/:id',
        element: React.createElement(DriverProposalDetailPageV2A, null),
    },
    {
        pathname: '/upload-document-v2a',
        element: React.createElement(VehicleProviderAddDriverFormPageV2A, null),
    },
    {
        pathname: '/redirect-page',
        element: React.createElement(RedirectPage, null),
    },
    // TBD: Remove this route if handled by native app
    {
        pathname: '/vehicle-provider-submit-details-page',
        element: React.createElement(VehicleProviderListingForm, null),
    },
    // Rental Summary Routes
    {
        pathname: '/rental-summary',
        element: React.createElement(RentalSummaryPage, null),
    },
    {
        pathname: '/rental-summary-v2',
        element: React.createElement(RentalSummaryPageV2, null),
    },
    {
        pathname: '/rental-summary/rental-agreement',
        element: React.createElement(RentalAgreementPage, null),
    },
    {
        pathname: '/rental-summary/supplemental-agreement',
        element: React.createElement(SupplementalAgreementPage, null),
    },
    // Rental Deposit Routes
    {
        pathname: '/rental-deposit',
        element: React.createElement(RentalDepositPage, null),
    },
    {
        pathname: '/schedule-vehicle-return',
        element: React.createElement(ScheduleVehicleReturnPage, null),
    },
    {
        pathname: '/chevron-discount-form',
        element: React.createElement(ChevronDiscountFormPage, null),
    },
    // Variable Rental Pricing Routers
    {
        pathname: '/rebate/introduction',
        element: React.createElement(RebateIntroductionPage, null),
    },
    {
        pathname: '/rebate/introduction-th',
        element: React.createElement(RebateIntroductionPageTH, null),
    },
    // Misc
    {
        pathname: '*',
        element: React.createElement(NotFoundPage, null),
    },
];
